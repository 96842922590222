import {
  faChartLine,
  faCheckDouble,
  faChevronDown,
  faCog,
  faDisplay,
  faDumbbell,
  faHeartPulse,
  faList,
  faMessage,
  faShareAlt,
  faShoppingCart,
  faTowerBroadcast,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useLayoutEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "./MenuItem";

import axios from "axios";
import $ from "jquery";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FormSelect } from "shards-react";
import Cookies from "universal-cookie";
import logo_white from "../../Images/logo_white.png";
import API from "../Api/API";
import AsyncImage from "../AsyncImage/AsyncImage";
import {
  default as AuthService,
  default as authService,
} from "../AuthService/AuthService";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import SmoothCollapse from "react-smooth-collapse";

import "dear-image.detect-background-color";
import DearImage from "dear-image";

const Sidebar = ({
  primaryColor,
  isAdmin,
  isClubManager,
  isSquadManager,
  isExerciseManager,
  isUser,
  noClubs,
  setNoClubs,
  setColorRefresh,
  isExpired,
  setIsExpired,
  setAfterExpiration,
  globalSquad,
  setGlobalSquad,
  setGlobalClub,
  sidebarRefresh,
  setSquadResultsShow,
  isRightClubManager,
  isRightSquadManager,
  setIsRightClubManager,
  setIsRightSquadManager,
  isRightUser,
  setIsRightUser,
}) => {
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);

  const [clubs, setClubs] = useState([]);

  const [activeMenuItem, setActiveMenuItem] = useState("");

  const [activeSubMenuItem, setActiveSubMenuItem] = useState("");

  const [prevActive, setPrevActive] = useState("");

  const [activeClub, setActiveClub] = useState();

  const [squadsForUser, setSquadsForUser] = useState([]);

  const { i18n, t } = useTranslation(["sidebar"]);

  useEffect(() => {
    const getData = async () => {
      AuthService.checkExpiration().then(async () => {
        const response = await fetchClubs();
        let sorted = response.sort((a, b) =>
          a.name.localeCompare(b.name, i18n.language)
        );

        const cookies = new Cookies();

        if (sorted.length === 0) {
          setNoClubs(true);
          cookies.remove("selectedClubId");
          setColorRefresh((c) => !c);
        } else {
          setNoClubs(false);
          setClubs(sorted);
          const clubId = parseInt(cookies.get("selectedClubId"));
          if (clubId) {
            let found = false;
            let i = 0;
            for (i; i < sorted.length; i++) {
              if (sorted[i].id === clubId) {
                found = true;
                break;
              }
            }
            let club;
            if (found) {
              $("#club_select").val(clubId);
              club = sorted.find((c) => c.id === clubId);
            } else {
              club = sorted[0];
            }
            club.squads.sort((a, b) =>
              a.name.localeCompare(b.name, i18n.language)
            );

            const index = club.squads.findIndex((s) => s.id === -1);
            if (index !== -1) {
              const squad = club.squads[index];
              club.squads.splice(index, 1);
              club.squads.push(squad);
            }

            club.squads.forEach((squad) => {
              squad.members.sort((a, b) =>
                (a.lastName + " " + a.firstName).localeCompare(
                  b.lastName + " " + b.firstName,
                  i18n.language
                )
              );
            });

            if(globalSquad) {
              const squad = club.squads.find(s => s.id === globalSquad.id);
              setGlobalSquad(squad);
            }
            
            setActiveClub(club);
            setGlobalClub(club);
            cookies.set("selectedClubId", club.id, {
              expires: new Date(moment().add(365, "d").format()),
            });
            if (club.primaryColor) {
              cookies.set("primaryColor", club.primaryColor, {
                expires: new Date(moment().add(365, "d").format()),
              });
            } else {
              cookies.remove("primaryColor");
            }

            setColorRefresh((c) => !c);

            if (
              new Date(club.subscriptionExpiration.replace(/ /g, "T")) <
              new Date()
            ) {
              setAfterExpiration(true);
            }

            const threeDays = new Date(
              club.subscriptionExpiration.replace(/ /g, "T")
            );
            threeDays.setDate(threeDays.getDate() + 3);
            if (threeDays < new Date()) {
              setIsExpired(true);
            }
          }
        }
      });
    };
    getData();
  }, [
    setNoClubs,
    setColorRefresh,
    setIsExpired,
    setAfterExpiration,
    sidebarRefresh,
  ]);

  useEffect(() => {
    if (activeClub) {

      const userId = authService.getUserId();
      let alreadySet = false;
      if (isClubManager) {
        if (activeClub.managers.find((m) => m.id === userId)) {
          setIsRightClubManager(true);
          setIsRightSquadManager(true);
          setSquadsForUser(activeClub.squads);
          alreadySet = true;
        }
      }
      
      if (!alreadySet && isSquadManager) {
        if (activeClub.squads) {
          const squads = activeClub.squads.filter((squad) => {
            return !!squad.managers.find((m) => m.id === userId);
          });
          setSquadsForUser(squads);
          setIsRightSquadManager(squads.length > 0);
        }
      }

      if (isUser) {
        const userSquads = activeClub.squads.filter((squad) => {
          return !!squad.members.find((m) => m.id === userId);
        });
        setIsRightUser(userSquads.length > 0);
      }
    }
  }, [activeClub, isClubManager, isSquadManager]);

  useLayoutEffect(() => {
    const path = window.location.pathname.substring(1);
    switch (path) {
      case "testresults":
        changeActive("item_testresults");
        setPrevActive("item_testresults");
        changeActiveSubmenu("item_myresults");
        break;
      case "testoverview":
        changeActive("item_testresults");
        setPrevActive("item_testresults");
        changeActiveSubmenu("item_myoverview");
        break;
      case "testmanagement":
        changeActive("item_management");
        setPrevActive("item_management");
        changeActiveSubmenu("item_testmanagement");
        break;
      case "exercisemanagement":
        changeActive("item_management");
        setPrevActive("item_management");
        changeActiveSubmenu("item_exercisemanagement");
        break;
      case "assignedexercises":
        changeActive("item_exercises");
        setPrevActive("item_exercises");
        changeActiveSubmenu("item_my_exercises");
        break;
      case "clubmanagement":
        changeActive("item_management");
        setPrevActive("item_management");
        changeActiveSubmenu("item_clubmanagement");
        break;
      case "useroverview":
        changeActive("item_management");
        setPrevActive("item_management");
        changeActiveSubmenu("item_useroverview");
        break;
      case "clubresults":
        changeActive("item_testresults");
        setPrevActive("item_testresults");
        changeActiveSubmenu("item_clubresults");
        break;
      case "squadresults":
        changeActive("item_testresults");
        setPrevActive("item_testresults");
        break;
      case "clubtestoverview":
        changeActive("item_testresults");
        setPrevActive("item_testresults");
        changeActiveSubmenu("item_testoverview");
        break;
      case "import":
        changeActive("item_import");
        setPrevActive("item_import");
        changeActiveSubmenu("");
        break;
      case "sales":
        changeActive("item_sales");
        setPrevActive("item_sales");
        changeActiveSubmenu("");
        break;
      case "shareddocuments":
        changeActive("item_share");
        setPrevActive("item_share");
        changeActiveSubmenu("item_shared_documents");
        break;
      case "sharedphotos":
        changeActive("item_share");
        setPrevActive("item_share");
        changeActiveSubmenu("item_shared_photos");
        break;
      case "sharedvideos":
        changeActive("item_share");
        setPrevActive("item_share");
        changeActiveSubmenu("item_shared_videos");
        break;
      case "healthrecords":
        changeActive("item_healthrecords");
        break;
      case "squad":
        changeActive("item_all_squads");
        setPrevActive("item_all_squads");
        if (globalSquad) {
          changeActiveSubmenu("item_squad_" + globalSquad.id);
        } else {
          changeActiveSubmenu("");
        }
        break;
      case "editsquadresults":
        changeActive("item_tests");
        setPrevActive("item_tests");
        if (globalSquad) {
          changeActiveSubmenu("item_test_squad_" + globalSquad.id);
        } else {
          changeActiveSubmenu("");
        }
        break;
      case "editassignedexercises":
        changeActive("item_exercises");
        setPrevActive("item_exercises");
        if (globalSquad) {
          changeActiveSubmenu("item_exercise_squad_" + globalSquad.id);
        } else {
          changeActiveSubmenu("");
        }
        break;
      case "userdashboard":
        changeActive("item_dashboard");
        setPrevActive("item_dashboard");
        changeActiveSubmenu("item_userdashboard");
        break;
      case "clubdashboard":
        changeActive("item_dashboard");
        setPrevActive("item_dashboard");
        changeActiveSubmenu("item_clubdashboard");
        break;
      case "":
      default:
        changeActive("");
        changeActiveSubmenu("");
        break;
    }
  }, [refresh, sidebarRefresh]);

  const fetchClubs = async () => {
    return axios
      .get(API.url + "api/club/allforuser", { withCredentials: true })
      .then((res) => res.data)
      .catch(() => AuthService.logout());
  };

  const changeActive = (item) => {
    const active = document.getElementById(activeMenuItem);
    if (active != null) {
      active.classList.remove("active-menu-item");
    }

    const newActive = document.getElementById(item);
    if (newActive != null) {
      newActive.classList.add("active-menu-item");
    }
    setActiveMenuItem(item);
  };

  const changeActiveSubmenu = (item) => {
    const active = document.getElementById(activeSubMenuItem);
    if (active != null) {
      active.classList.remove("active-submenu-item");
    }

    const newActive = document.getElementById(item);
    if (newActive != null) {
      newActive.classList.add("active-submenu-item");
    }
    setActiveSubMenuItem(item);
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const selectColor = {
    // color: "white",
    borderColor: primaryColor,
  };

  const toggleSidebar = () => {
    document.getElementById("sidebar").classList.toggle("active");
  };

  const changeClubId = (e) => {
    e.preventDefault();
    const cookies = new Cookies();
    cookies.set("selectedClubId", e.target.value, {
      expires: new Date(moment().add(365, "d").format()),
    });

    window.location = "/";
  };

  const handleSubMenuClick = (e, id) => {
    if (e) {
      e.preventDefault();
    }
    if (activeMenuItem !== id) {
      setPrevActive(activeMenuItem);
      changeActive(id);
    } else if (activeMenuItem !== prevActive) {
      changeActive(prevActive);
    } else {
      changeActive("");
    }
  };

  return [
    <div
      className="sidebar-mobile-header"
      key="mobile-header"
      style={primaryColorBg}
    >
      {activeClub && activeClub.logoPath ? (
        <div
          className="club-logo-wrapper"
          onClick={() => {
            document.getElementById("sidebar").classList.remove("active");
            navigate("/");
            setRefresh((r) => !r);
          }}
        >
          <AsyncImage
            className="club-logo"
            src={API.url + "api/club/download/" + activeClub.id}
            onLoad={async (e) => {
              const image = e.target;
              image.parentNode.style.backgroundColor =
                await DearImage.detectBackgroundColor(image);
            }}
          />
        </div>
      ) : (
        <img
          className="club-logo logo-hp"
          src={logo_white}
          alt="Health Profile"
        />
      )}
      <FontAwesomeIcon
        className={"bars-icon"}
        style={{ ...primaryColorBg, color: "white" }}
        icon={faList}
        id="btn"
        onClick={toggleSidebar}
      />
    </div>,
    <div className="sidebar" id="sidebar" key="sidebar" style={primaryColorBg}>
      <div className="sidebar-content-wrapper">
        {activeClub && activeClub.logoPath && (
          <div
            className="logo_content club_logo_content"
            onClick={() => {
              document.getElementById("sidebar").classList.remove("active");
              navigate("/");
              setRefresh((r) => !r);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="logo club_logo">
              <AsyncImage
                src={API.url + "api/club/download/" + activeClub.id}
                onLoad={async (e) => {
                  const image = e.target;
                  image.parentNode.style.backgroundColor =
                    await DearImage.detectBackgroundColor(image);
                }}
              />
            </div>
          </div>
        )}
        {clubs.length > 1 && (
          <div className="club_select">
            <FormSelect
              id="club_select"
              style={selectColor}
              onChange={changeClubId}
              defaultValue={activeClub?.id}
            >
              {clubs.map((club, index) => (
                <option key={"club_" + club.id} value={club.id}>
                  {club.name}
                </option>
              ))}
            </FormSelect>
          </div>
        )}
        <ul className="nav_list">
          {(isRightClubManager || isRightSquadManager || isRightUser) &&
            !isExpired && [
              <MenuItem
                key="item_dashboard"
                id="item_dashboard"
                icon={faDisplay}
                text={t("overview")}
                href="/"
                primaryColor={primaryColor}
                textColor={primaryColor}
                navigate={
                  (isRightClubManager || isRightSquadManager) && isRightUser ? null : navigate
                }
                setRefresh={setRefresh}
                clickEvent={(event) => {
                  setGlobalClub(activeClub);
                  handleSubMenuClick(event, "item_dashboard");
                }}
                chevron={
                  (isRightClubManager || isRightSquadManager) && isRightUser
                    ? faChevronDown
                    : null
                }
              />,
              (isRightClubManager || isRightSquadManager) && isRightUser && (
                <SmoothCollapse
                  key="dashboard_submenu"
                  expanded={activeMenuItem === "item_dashboard"}
                  className="sub-menu"
                >
                  <ul>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/clubdashboard");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_clubdashboard">{t("club_dashboard")}</span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/userdashboard");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_userdashboard">{t("user_dashboard")}</span>
                    </li>
                  </ul>
                </SmoothCollapse>
              ),
            ]}
          {!noClubs &&
            (isRightSquadManager || isRightClubManager) && [
              <MenuItem
                key="item_squads"
                id="item_all_squads"
                icon={faUsers}
                text={t("squads")}
                href=""
                primaryColor={primaryColor}
                textColor={primaryColor}
                navigate={null}
                setRefresh={setRefresh}
                clickEvent={(event) =>
                  handleSubMenuClick(event, "item_all_squads")
                }
                chevron={faChevronDown}
              />,
              <SmoothCollapse
                key="squads_submenu"
                expanded={activeMenuItem === "item_all_squads"}
                className="sub-menu"
              >
                <ul>
                  {squadsForUser.map((squad) => (
                    <li
                      key={"squad" + squad.id}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/squad");
                        setGlobalSquad(squad);
                        setGlobalClub(activeClub);
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id={"item_squad_" + squad.id}>
                        {squad.id < 0 ? t("history") : squad.name}
                      </span>
                    </li>
                  ))}
                  {isRightClubManager && (
                    <li
                      key={"new_squad"}
                      onClick={(e) => {
                        e.preventDefault();
                        setGlobalClub(activeClub);
                        document.getElementById(
                          "global_squad_form"
                        ).style.display = "block";
                      }}
                      className="li-plus"
                    >
                      <span id={"item_add_squad"}>{t("add_squad")}</span>
                    </li>
                  )}
                </ul>
              </SmoothCollapse>,
            ]}
          {!noClubs &&
            !isExpired &&
            (isRightSquadManager || isRightClubManager || isRightUser) && [
              <MenuItem
                key="item_testresults"
                id="item_testresults"
                icon={faChartLine}
                text={t("results")}
                href="/testresults"
                setRefresh={setRefresh}
                primaryColor={primaryColor}
                textColor={primaryColor}
                clickEvent={(event) =>
                  handleSubMenuClick(event, "item_testresults")
                }
                navigate={null}
                chevron={faChevronDown}
              />,
              <SmoothCollapse
                key="testresults_submenu"
                expanded={activeMenuItem === "item_testresults"}
                className="sub-menu"
              >
                <ul>
                  {isRightUser && [
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/testresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                      key={"my_results"}
                    >
                      <span id="item_myresults">
                        {t(
                          isRightClubManager || isRightSquadManager
                            ? "my_test_results"
                            : "test_results"
                        )}
                      </span>
                    </li>,
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/testoverview");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                      key={"my_overview"}
                    >
                      <span id="item_myoverview">
                        {t(
                          t(
                            isRightClubManager || isRightSquadManager
                              ? "my_test_overview"
                              : "test_overview"
                          )
                        )}
                      </span>
                    </li>,
                  ]}
                  {(isRightClubManager || isRightSquadManager) && [
                    <li
                      key="team_results_quadrants"
                      onClick={(e) => {
                        e.preventDefault();
                        setSquadResultsShow("quadrants");
                        changeActiveSubmenu("item_squadresults_quadrants");
                        navigate("/squadresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_squadresults_quadrants">
                        {t("quadrant_graphs")}
                      </span>
                    </li>,
                    <li
                      key="team_results_rpe"
                      onClick={(e) => {
                        e.preventDefault();
                        setSquadResultsShow("rpe");
                        changeActiveSubmenu("item_squadresults_rpe");
                        navigate("/squadresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_squadresults_rpe">{t("rpe_records")}</span>
                    </li>,
                    <li
                      key="team_results_compare"
                      onClick={(e) => {
                        e.preventDefault();
                        setSquadResultsShow("compare");
                        changeActiveSubmenu("item_squadresults_compare");
                        navigate("/squadresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_squadresults_compare">
                        {t("squad_compare")}
                      </span>
                    </li>,
                    <li
                      key="team_results_health_records"
                      onClick={(e) => {
                        e.preventDefault();
                        setSquadResultsShow("health_records");
                        changeActiveSubmenu("item_squadresults_health_records");
                        navigate("/squadresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_squadresults_health_records">
                        {t("injury_overview")}
                      </span>
                    </li>,
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/clubtestoverview");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                      key={"test_overview"}
                    >
                      <span id="item_testoverview">
                        {t(t("test_overview"))}
                      </span>
                    </li>,
                  ]}
                  {isRightClubManager && (
                    <li
                      key="club_results"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/clubresults");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_clubresults">{t("club_results")}</span>
                    </li>
                  )}
                </ul>
              </SmoothCollapse>,
            ]}
          {!noClubs &&
            !isExpired &&
            (isRightSquadManager || isRightClubManager) && [
              <MenuItem
                key="item_tests"
                id="item_tests"
                icon={faCheckDouble}
                text={t("tests")}
                href=""
                primaryColor={primaryColor}
                textColor={primaryColor}
                navigate={null}
                setRefresh={setRefresh}
                clickEvent={(event) => handleSubMenuClick(event, "item_tests")}
                chevron={faChevronDown}
              />,
              <SmoothCollapse
                key="tests_submenu"
                expanded={activeMenuItem === "item_tests"}
                className="sub-menu"
              >
                <ul>
                  {squadsForUser.map(
                    (squad) =>
                      squad.id > 0 && (
                        <li
                          key={"test_squad_" + squad.id}
                          onClick={(e) => {
                            e.preventDefault();
                            setGlobalSquad(squad);
                            navigate("/editsquadresults");
                            document
                              .getElementById("sidebar")
                              .classList.remove("active");
                            setRefresh((r) => !r);
                          }}
                        >
                          <span id={"item_test_squad_" + squad.id}>
                            {squad.name}
                          </span>
                        </li>
                      )
                  )}
                </ul>
              </SmoothCollapse>,
            ]}
          {!noClubs &&
            !isExpired &&
            (isRightSquadManager || isRightClubManager || isRightUser) && [
              <MenuItem
                key="item_exercises"
                id="item_exercises"
                icon={faDumbbell}
                text={t("exercises")}
                href="/assignedexercises"
                primaryColor={primaryColor}
                textColor={primaryColor}
                setRefresh={setRefresh}
                clickEvent={(event) =>
                  handleSubMenuClick(event, "item_exercises")
                }
                navigate={isRightSquadManager || isRightClubManager ? null : navigate}
                chevron={isRightSquadManager || isRightClubManager ? faChevronDown : null}
              />,
              (isRightSquadManager || isRightClubManager) && (
                <SmoothCollapse
                  key="exercise_submenu"
                  expanded={activeMenuItem === "item_exercises"}
                  className="sub-menu"
                >
                  <ul>
                    {isRightUser && (
                      <li
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/assignedexercises");
                          document
                            .getElementById("sidebar")
                            .classList.remove("active");
                          setRefresh((r) => !r);
                        }}
                      >
                        <span id="item_my_exercises">
                          {t("my_assigned_exercises")}
                        </span>
                      </li>
                    )}
                    {squadsForUser.map(
                      (squad) =>
                        squad.id > 0 && (
                          <li
                            key={"exercise_squad_" + squad.id}
                            onClick={(e) => {
                              e.preventDefault();
                              setGlobalSquad(squad);
                              navigate("/editassignedexercises");
                              document
                                .getElementById("sidebar")
                                .classList.remove("active");
                              setRefresh((r) => !r);
                            }}
                          >
                            <span id={"item_exercise_squad_" + squad.id}>
                              {squad.name}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </SmoothCollapse>
              ),
            ]}
          {isRightUser && !isExpired && (
            <MenuItem
              id="item_healthrecords"
              icon={faHeartPulse}
              text={t("health_records")}
              href="/healthrecords"
              primaryColor={primaryColor}
              textColor={primaryColor}
              navigate={navigate}
              setRefresh={setRefresh}
            />
          )}
          {!noClubs &&
            !isExpired &&
            (isRightSquadManager || isRightClubManager || isRightUser) && [
              <MenuItem
                key="item_share"
                id="item_share"
                icon={faShareAlt}
                text={
                  isRightClubManager || isRightSquadManager ? t("sharing") : t("files")
                }
                href=""
                primaryColor={primaryColor}
                textColor={primaryColor}
                setRefresh={setRefresh}
                clickEvent={(event) => handleSubMenuClick(event, "item_share")}
                navigate={null}
                chevron={faChevronDown}
              />,
              <SmoothCollapse
                key="exercise_submenu"
                expanded={activeMenuItem === "item_share"}
                className="sub-menu"
              >
                <ul>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/shareddocuments");
                      document
                        .getElementById("sidebar")
                        .classList.remove("active");
                      setRefresh((r) => !r);
                    }}
                  >
                    <span id="item_shared_documents">{t("documents")}</span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/sharedphotos");
                      document
                        .getElementById("sidebar")
                        .classList.remove("active");
                      setRefresh((r) => !r);
                    }}
                  >
                    <span id="item_shared_photos">{t("photos")}</span>
                  </li>
                  <li
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/sharedvideos");
                      document
                        .getElementById("sidebar")
                        .classList.remove("active");
                      setRefresh((r) => !r);
                    }}
                  >
                    <span id="item_shared_videos">{t("videos")}</span>
                  </li>
                </ul>
              </SmoothCollapse>,
            ]}
          {/* {!noClubs && (isSquadManager || isClubManager) && (
            <MenuItem
              id="item_club"
              icon={faShieldAlt}
              text={t("club")}
              href="/club"
              primaryColor={primaryColor}
              textColor={primaryColor}
              navigate={navigate}
              setRefresh={setRefresh}
            />
          )} */}
          {/* {(isClubManager || isSquadManager) && !isExpired && (
            <MenuItem
              id="item_import"
              icon={faFileImport}
              text={t("import")}
              href="/import"
              primaryColor={primaryColor}
              textColor={primaryColor}
              navigate={navigate}
            />
          )} */}
          {!isExpired &&
            (isExerciseManager || isRightClubManager || isAdmin) && [
              <MenuItem
                key="item_exercise"
                id="item_management"
                icon={isAdmin ? faCog : faDumbbell}
                text={isAdmin ? t("management") : t("exercise_management")}
                href="/exercisemanagement"
                primaryColor={primaryColor}
                textColor={primaryColor}
                navigate={isAdmin ? null : navigate}
                setRefresh={setRefresh}
                clickEvent={
                  isAdmin
                    ? (event) => handleSubMenuClick(event, "item_management")
                    : null
                }
                chevron={isAdmin ? faChevronDown : null}
              />,
              isAdmin && (
                <SmoothCollapse
                  key="management_submenu"
                  expanded={activeMenuItem === "item_management"}
                  className="sub-menu"
                >
                  <ul>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/exercisemanagement");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_exercisemanagement">
                        {t("exercise_management")}
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/testmanagement");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_testmanagement">
                        {t("test_management")}
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/clubmanagement");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_clubmanagement">
                        {t("club_management")}
                      </span>
                    </li>
                    <li
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/useroverview");
                        document
                          .getElementById("sidebar")
                          .classList.remove("active");
                        setRefresh((r) => !r);
                      }}
                    >
                      <span id="item_useroverview">{t("users_overview")}</span>
                    </li>
                  </ul>
                </SmoothCollapse>
              ),
            ]}
          <li id={"item_blog"}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://healthprofile.digital/blog/"
            >
              <FontAwesomeIcon icon={faTowerBroadcast} />
              <span className="links_name">{t("blog")}</span>
            </a>
          </li>
          {/* !isExpired && (
            <MenuItem
              id="item_sales"
              icon={faShoppingCart}
              text={t("sales")}
              href="/sales"
              primaryColor={primaryColor}
              textColor={primaryColor}
              navigate={navigate}
              setRefresh={setRefresh}
            />
          )*/}
        </ul>
        <div className="sidebar-bottom-container">
          <div className="shortcut-buttons-container">
            {/* <div
              className="shortcut-button"
              onClick={() => {
                document.getElementById("sidebar").classList.remove("active");
                setRefresh((r) => !r);
                navigate("/profile");
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </div> */}
            <div
              className="shortcut-button"
              onClick={() => {
                document.getElementById("sidebar").classList.remove("active");
                setRefresh((r) => !r);
                setGlobalClub(activeClub);
                navigate("/settings");
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
            {(isRightSquadManager || isRightClubManager) && (
              <div
                className="shortcut-button"
                onClick={() => {
                  document.getElementById("sidebar").classList.remove("active");
                  setRefresh((r) => !r);
                  document.getElementById("global_message_form").style.display =
                    "block";
                }}
              >
                <FontAwesomeIcon icon={faMessage} />
              </div>
            )}
            {/* <div className="shortcut-button">
              <FontAwesomeIcon icon={faQuestion} />
            </div> */}
          </div>
          <div
            className="logout_container"
            id="logout"
            onClick={AuthService.logout}
          >
            <span className="logout_text">{t("logout")}</span>
          </div>
          <div className="language_container">
            <LanguageSelector />
          </div>
          <div
            className="logo_content"
            onClick={() => {
              document.getElementById("sidebar").classList.remove("active");
              navigate("/");
              setRefresh((r) => !r);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="logo">
              <img src={logo_white} alt="Health Profile" />
            </div>
          </div>
        </div>
      </div>
    </div>,
  ];
};

export default Sidebar;
